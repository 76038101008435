<template>
    <v-app>
        <v-app-bar 
            app
            color="primary"
            dark
            clipped-left
            elevation="0"
        > 
            <div :class="schoolNameClass">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-4"
                    contain
                    src="../assets/logo.png"
                    transition="scale-transition"
                    width="60"
                />
                {{ schoolName }} School
                
            </div>
            <v-spacer></v-spacer>
            <v-avatar
                color="white"
                class="mr-6"
                size="36"                                
            >
                <span :class="avatarClass">{{ initials }}</span>
            </v-avatar>
            <Logout/>
        </v-app-bar>

        <v-main class="grey lighten-5">
            <v-container
                fluid                
            >
                <v-row v-if="welcomeMessage" class="mt-10">
                    <v-card 
                        class="grey lighten-5 "                        
                        flat
                        width="460"
                        height="40"
                    >
                        
                        <div :class="textClass">
                            <span class="mr-3 d-flex align-end">Welcome</span> 
                            <span class="font-weight-light font-italic text-h5 d-flex align-end">{{ user }}</span>
                        </div>                       
                        <div class="caption" v-if="lastLogin">Last login: </div>                        
                        
                    </v-card>
                    <v-spacer></v-spacer>
                    <span
                        :class="textClassRight"
                    >{{ currentAcademicPeriod }}</span>
                </v-row>
                <v-row>
                    <v-col
                        cols="1"
                    >
                        <v-card
                            width="70"
                            flat
                            class="d-flex flex-column align-center grey lighten-5"
                            v-if="!mainMenu"
                        >
                            <v-expand-transition>
                                <v-btn
                                    :color="buttonColor"
                                    dark
                                    fab
                                    small
                                    @click="showMainMenu"    
                                >
                                    <v-icon>
                                        mdi-arrow-left
                                    </v-icon>
                                    
                                </v-btn>
                            </v-expand-transition>
                            <div
                                class="caption mt-2"
                            >
                                Main Menu
                            </div>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="11"
                    >
                        <main-menu v-if="mainMenu"></main-menu>
                        <mark-sheet v-if="markSheet"></mark-sheet>
                        <edit-view-term-reports v-if="termReports"></edit-view-term-reports>
                        <print-view-reports v-if="reports"></print-view-reports>
                        <employees v-if="employees"></employees>
                        <student-subjects v-if="studentSubjects"></student-subjects>
                        <edit-view-students v-if="editViewStudents"></edit-view-students>
                        <reset-password v-if="resetPassword"></reset-password>
                        <settings v-if="settings"></settings>
                    </v-col>
                    
                </v-row>                    
                              
            </v-container> 
        </v-main>        
        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="closeModal"
        >
            {{ modalMessage }}
        </sweet-modal>
        <v-overlay
            :value="appOverlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import MainMenu from './MainMenu';
import MarkSheet from './MarkSheet';
import EditViewTermReports from './EditViewTermReports';
import PrintViewReports from './PrintViewReports';
import Employees from './Employees';
import StudentSubjects from './StudentSubjects';
import EditViewStudents from './EditViewStudents';
import ResetPassword from './ResetPassword';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import Settings from './Settings';

export default {
    name: 'Admin',
    components: {
        Logout,        
        MainMenu,
        MarkSheet,
        EditViewTermReports,
        PrintViewReports,
        Employees,
        StudentSubjects,
        EditViewStudents,
        ResetPassword,
        SweetModal, 
        Settings,        
    },
    created(){ 
        this.initialize();                
    },
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        }
    },
    computed: {      
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
             
        ...mapGetters({
            getUser: 'auth/getUser',
            schoolName: 'app/getSchoolName',
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',
            user: 'auth/getEmployeeSignedIn',
            initials: 'auth/getEmployeeInitials',
            welcomeMessage: 'mainMenu/getWelcomeMessage',
            mainMenu: 'mainMenu/getDashboard',
            markSheet: 'mainMenu/getMarkSheet',
            termReports: 'mainMenu/getTermReports',
            reports: 'mainMenu/getReports',
            employees: 'mainMenu/getEmployees',
            studentSubjects: 'mainMenu/getStudentSubjects',
            editViewStudents: 'mainMenu/getEditViewStudents',
            resetPassword: 'mainMenu/getResetPassword',
            modal: 'app/getModal',
            modalTitle: 'app/getModalTitle',
            modalIcon: 'app/getModalIcon',
            modalMessage: 'app/getModalMessage',           
            appOverlay: 'app/getAppOverlay',
            settings: 'mainMenu/getSettings',             
        }),
        titleClass(){
            return 'subtitle-1 font-weight-light font-italic  white--text';
        },
        textClass(){
            return 'd-flex justify-center text-body-1 primary--text';
        },
        textClassRight(){
            return 'd-flex mr-10 justify-center text-body-1 primary--text ';
        },
        avatarClass(){
            return 'primary--text button'
        },
        buttonColor(){
            return 'primary';
        },
        schoolNameClass(){
            return 'd-flex align-center text-uppercase font-weight-bold white--text';
        }        
    },
    
    data: () => ({                
        lastLogin: false,
        currentAcademicPeriod: null,         
    }),
    
    methods: {
        ...mapMutations({
            setMainMenu: 'mainMenu/setMainMenu', 
            setMarkSheet: 'mainMenu/setMarkSheet',
            setTermReports: 'mainMenu/setTermReports',
            setPrintViewReports: 'mainMenu/setReports',
            setEmployees: 'mainMenu/setEmployees',
            setStudentSubjects: 'mainMenu/setStudentSubjects',
            setEditViewStudents: 'mainMenu/setEditViewStudents',
            setResetPassword: 'mainMenu/setResetPassword',                       
            setModal: 'app/setModal',
            setAppOverlay: 'app/setAppOverlay',
            setAcademicYearId: 'app/setAcademicYearId',
            setAcademicTermId: 'app/setAcademicTermId',
            setAcademicYear: 'app/setAcademicYear',
            setTerm: 'app/setTerm',
            setWelcomeMessage: 'mainMenu/setWelcomeMessage',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading',            
            setPresetComment: 'markSheet/setPresetComment',
            setSettings: 'mainMenu/setSettings',
        }),
        ...mapActions({
            academicPeriod: 'app/getAcademicPeriod',
        }),
        async initialize(){
            try {
                let response = await this.academicPeriod();                
                let academicYearId = response.data.academic_year_id;
                let academicTermId = response.data.id;
                let term = response.data.term; 
                
                this.setAcademicTermId(academicTermId);               
                this.setAcademicYearId(academicYearId);
                this.setTerm(term);                
                this.setCurrentPeriod(term, academicYearId);
                              
            } catch (error) {
                console.log(error);
            }           
            
            this.setAppOverlay(false);
        },
        setCurrentPeriod(term, academicYearId){
            let year = academicYearId.toString().substr(0, 4) + '-' + academicYearId.toString().substr(4);
            this.setAcademicYear(year);
            this.currentAcademicPeriod = `Term: ${ term } Year: ${ year}`;  
        },        
        openModal(){            
            this.$refs.modal.open();
        },
        closeModal(){
            this.setModal(false);
        },
        showMainMenu(){                        
            this.setWelcomeMessage(true);
            this.setMarkSheetLoading(false);
            this.setMainMenu(true);
            this.setMarkSheet(false);
            this.setTermReports(false);
            this.setPrintViewReports(false);
            this.setEmployees(false);
            this.setStudentSubjects(false);
            this.setEditViewStudents(false);
            this.setResetPassword(false);
            this.setSettings(false);
        },        
    }
}
</script>